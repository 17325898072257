import React, { useState } from "react"
import { Wide } from "../components/layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Title } from "../components/title"

const pay = (amount) => {
  if (amount === '') {
    return;
  }
  fetch(`/.netlify/functions/payment?amount=${amount}`)
    .then(response => response.json())
    .then(data => window.location = data.redirect);
}

const Support = ({data, location}) => {
  const [amount, setAmount] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);
  return (
    <Layout>
      <SEO title="Steun Vonk" path={location.pathname} />
      <Wide>
        <div className="max-w-3xl m-auto px-4">
          <Title>Steun Vonk</Title>
          <p className="pb-8">Wat fijn dat je overweegt om Vonk te steunen. We zijn al hartstikke blij dat je de site leest, maar we willen ook graag de auteurs van de verhalen en de maker van de omslag van Vonk Magazine kunnen betalen.</p>
          <div className="relative pt-1">
            <div className="overflow-hidden h-6 mb-1 text-xs flex rounded-full bg-gray-400">
              <div className="w-1/4 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-700"></div>
            </div>
          </div>
          <p className="text-gray-400 text-sm pb-8">Deze balk geeft aan hoe ver we zijn met het financieren van de volgende editie van Vonk Magazine.</p>
          <p className="pb-8">Bijdragen aan Vonk Magazine kan via iDeal. Kies een bedrag dat je passend vindt, en verder werkt het zoals elke andere iDeal betaling.</p>
          <p className="pb-8">Wil je Vonk liever op een andere manier steunen? Dat kan natuurlijk ook! Deel bijvoorbeeld je favoriete artikel op social media, of schrijf je in voor onze nieuwsbrief.</p>
          <div className="grid grid-cols-3 gap-8">
            {[1, 5, 10].map(a => <div key={`amount-${a}`} className={`cursor-pointer border flex items-center justify-center p-8 hover:bg-red-500 hover:text-gray-100 rounded-xl font-sans text-3xl font-medium ${selectedAmount === a ? "bg-red-700 hover:bg-red-700 text-gray-100" : "bg-transparent"}`} onClick={() => setAmount(a) || setSelectedAmount(a)}>€ {a}</div>)}
          </div>
          <div className="flex items-center justify-center p-8">
            <div className="text-center">
              <p>Of kies zelf een bedrag</p>
              <p className="text-3xl font-sans">€ <input className="w-24 text-right border text-3xl rounded-xl" pattern="[0-9]*" type="number" value={amount} onChange={(e) => e.target.validity.valid && setAmount(e.target.value) || setSelectedAmount(null)} /></p>
            </div>
          </div>
          {amount && <div className="flex flex-col items-center justify-center">
            <p>Wil je op de bedankt pagina genoemd worden, vul dan hier je naam in. Het is niet verplicht en we zijn je hoe dan ook dankbaar.</p>
            <input placeholder="Naam" className="appearance-none font-sans block my-4 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm"/>
            <a href="#" className="inline-block rounded-full bg-red-700 text-gray-100 py-3 px-6" onClick={() => pay(amount)}>Steun Vonk voor € {amount}</a>
          </div>}
        </div>
      </Wide>
    </Layout>
  )
}

export default Support;
